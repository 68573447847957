<template>
  <div class="main-body">
    <a-form :model="formState" name="basic" :label-col="labelCol" :wrapper-col="wrapperCol" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="基础设置">
          <div class="tab-content">
            <div class="brick-item">
              <div class="brick-title">商品类型</div>
              <div class="brick-body">
                <a-radio-group v-model:value="formState.goods_type">
                  <a-radio value="1">实物商品(需要物流)</a-radio>
                  <a-radio value="2">虚拟商品(无需物流)</a-radio>
                </a-radio-group>
              </div>
            </div>
            <div class="brick-item">
              <div class="brick-title">基础信息</div>
              <div class="brick-body">
                <a-form-item label="商品名称" name="goods_name" :rules="[{ required: true, message: '请输入商品名称!' }]">
                  <a-input class="half-input" v-model:value="formState.goods_name" placeholder="请输入商品名称，不能超过60个字符" />
                </a-form-item>

                <a-form-item label="商品名称" name="introduction">
                  <a-textarea class="half-input" v-model:value="formState.introduction" placeholder="请输入促销语，不能超过100个字符" allow-clear />
                </a-form-item>

                <a-form-item label="关键词" name="keywords">
                  <a-input class="half-input" v-model:value="formState.keywords" placeholder="商品关键词用于SEO搜索，不能超过100个字符" />
                </a-form-item>

                <a-form-item label="商品主图" name="images" :rules="[{ required: true, message: '请至少上传一张商品主图!' }]">
                  <CsImage :multiple="true" v-model:imgMulti="formState.images" />
                  <div class="extra">
                    <p>第一张图片将作为商品主图,支持同时上传多张图片,多张图片之间可随意调整位置；</p>
                    <p>支持jpg、gif、png格式上传或从图片空间中选择，建议使用尺寸800x800像素以上、大小不超过1M的正方形图片；</p>
                    <p>上传后的图片将会自动保存在图片空间的默认分类中，最多上传10张（至少1张）</p>
                  </div>
                </a-form-item>

                <a-form-item label="商品视频" name="video">
                  <div class="goods-video-box">
                    <video class="goods-video" :src="formState.video" controls="" preload="auto"></video>
                  </div>
                  <a-input class="half-input" v-model:value="formState.video" placeholder="在此输入外链视频地址" />
                  <div class="extra">
                    <p>1、检查upload文件夹是否有读写权限。</p>
                    <p>2、PHP默认上传限制为2MB，需要在php.ini配置文件中修改“post_max_size”和“upload_max_filesize”的大小。</p>
                    <p>3、视频支持手动输入外链视频地址或者上传本地视频文件</p>
                    <p>4、必须上传.mp4视频格式</p>
                    <p>5、视频文件大小不能超过500MB</p>
                  </div>
                </a-form-item>

                <a-form-item label="商品品牌" name="brand_id">
                  <a-select class="medium-input" v-model:value="formState.brand_id" placeholder="请选择">
                    <a-select-option value="0">无</a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item label="商品标签" name="label_id">
                  <a-select class="medium-input" v-model:value="formState.label_id" placeholder="请选择">
                    <a-select-option value="0">无</a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item label="商品服务" name="service">
                  <a-checkbox-group v-model:value="formState.service" :options="plainOptions" />
                </a-form-item>

                <a-form-item label="商品分类" name="category_id">
                  <a-select class="medium-input" v-model:value="formState.category_id" placeholder="请选择">
                    <a-select-option value="0">无</a-select-option>
                  </a-select>
                  <div class="extra">
                    <p>商品可以属于多个分类，最多10个</p>
                  </div>
                </a-form-item>

                <a-form-item label="是否上架" name="goods_state">
                  <a-radio-group v-model:value="formState.goods_state">
                    <a-radio value="1">立刻上架</a-radio>
                    <a-radio value="0">放入仓库</a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item label="定时下架" name="time_under">
                  <a-radio-group v-model:value="formState.time_under">
                    <a-radio value="1">启用</a-radio>
                    <a-radio value="0">不启用</a-radio>
                  </a-radio-group>
                  <div v-if="formState.time_under == '1'" class="select-time">
                    <a-date-picker show-time placeholder="请选择时间" />
                  </div>
                </a-form-item>

              </div>
            </div>
            <div class="brick-item">
              <div class="brick-title">配送信息</div>
              <div class="brick-body">
                <a-form-item label="是否包邮" name="is_postage">
                  <a-radio-group v-model:value="formState.is_postage">
                    <a-radio value="1">是</a-radio>
                    <a-radio value="0">否</a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="价格库存">
          <div class="tab-content">
            <a-form-item label="启用多规格" name="is_spec">
              <a-switch v-model:checked="formState.is_spec" />
            </a-form-item>
            <a-form-item label="商品单位" name="goods_unit">
              <a-input class="medium-input" v-model:value="formState.goods_unit" placeholder="请输入商品单位" />
            </a-form-item>
            <a-form-item label="虚拟销量" name="virtual_sale">
              <a-input-number class="medium-input" v-model:value="formState.virtual_sale" :min="0" :max="1000000" placeholder="请输入虚拟销量" />件
              <div class="extra">
                <p>该设置不计入商品统计数据，只做显示使用</p>
              </div>
            </a-form-item>
            <a-form-item label="是否限购" name="is_quota">
              <a-radio-group v-model:value="formState.is_quota">
                <a-radio value="0">否</a-radio>
                <a-radio value="1">是</a-radio>
              </a-radio-group>
              <div class="extra">
                <p>启用限购后，购买商品时，会对该商品购买量做限制判断。</p>
              </div>
            </a-form-item>
            <a-form-item label="起售" name="start_sale">
              <a-input-number class="medium-input" v-model:value="formState.start_sale" :min="0" :max="1000000" placeholder="请输入起售数量" />件
              <div class="extra">
                <p>起售数量超出商品库存时，买家无法购买该商品</p>
              </div>
            </a-form-item>
            <a-form-item label="会员等级折扣" name="is_discount">
              <a-radio-group v-model:value="formState.is_discount">
                <a-radio value="0">否</a-radio>
                <a-radio value="1">是</a-radio>
              </a-radio-group>
              <div class="extra">
                <p>按照默认会员等级折扣优惠</p>
              </div>
            </a-form-item>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="商品详情">
          <div class="tab-content">
            <div class="goods-detail">
              <BasicEditor v-model="formState.content"></BasicEditor>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" tab="商品参数">
          <div class="tab-content">
            <a-form-item label="商品参数模板" name="tpl_id">
              <a-select class="medium-input" v-model:value="formState.tpl_id" placeholder="请选择">
                <a-select-option value="0">无</a-select-option>
              </a-select>
              <div class="extra">
                <p>商品可以添加自定义商品参数，也可以通过参数模板批量设置商品参数</p>
              </div>
            </a-form-item>
          </div>
        </a-tab-pane>
        <a-tab-pane key="5" tab="高级设置">
          <div class="tab-content">
            <div class="brick-item">
              <div class="brick-title">高级设置</div>
              <div class="brick-body">
                <a-form-item label="排序" name="sort">
                  <a-input-number class="medium-input" v-model:value="formState.sort" :min="0" :max="1000000" />
                  <div class="extra">
                    <p>商品默认排序号为0，数字越大，排序越靠前，数字重复，则最新添加的靠前。</p>
                  </div>
                </a-form-item>
                <a-form-item label="商品详情显示库存" name="stock_show">
                  <a-radio-group v-model:value="formState.stock_show">
                    <a-radio value="1">显示</a-radio>
                    <a-radio value="0">隐藏</a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item label="商品详情显示销量" name="sale_show">
                  <a-radio-group v-model:value="formState.sale_show">
                    <a-radio value="1">显示</a-radio>
                    <a-radio value="0">隐藏</a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item label="划线价显示" name="market_price_show">
                  <a-radio-group v-model:value="formState.market_price_show">
                    <a-radio value="1">显示</a-radio>
                    <a-radio value="0">隐藏</a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item label="商品详情显示弹幕" name="barrage_show">
                  <a-radio-group v-model:value="formState.barrage_show">
                    <a-radio value="1">显示</a-radio>
                    <a-radio value="0">隐藏</a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item label="商品海报" name="template_id">
                  <a-select class="medium-input" v-model:value="formState.template_id" placeholder="请选择商品海报">
                    <a-select-option value="0">无</a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <div class="form-bar">
        <a-space>
          <a-button v-if="parseInt(activeKey) > 1" @click="lastStep()">上一步</a-button>
          <a-button type="primary" html-type="submit">保存</a-button>
          <a-button v-if="parseInt(activeKey) < 5" @click="nextStep()">下一步</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import CsImage from "@/components/UpImage";
import BasicEditor from "@/components/Editor";
export default defineComponent({
  components: {
    CsImage,
    BasicEditor,
  },
  setup() {
    const formState = reactive({
      goods_type: "1",
      goods_name: "",
      introduction: "",
      keywords: "",
      images: [],
      video: "",
      brand_id: "0",
      label_id: "0",
      service: [],
      category_id: "0",
      goods_state: "1",
      time_under: "0",
      is_postage: "1",

      is_spec: "1",
      goods_unit: "",
      virtual_sale: "0",
      is_quota: "0",
      start_sale: "0",

      content: "",

      tpl_id: "",

      sort: "100",
      stock_show: "1",
      sale_show: "1",
      market_price_show: "1",
      barrage_show: "1",
      template_id: null,
    });

    const onFinish = (values) => {
      console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const activeKey = ref("1");

    const lastStep = () => {
      activeKey.value = (parseInt(activeKey.value) - 1).toString();
    };

    const nextStep = () => {
      activeKey.value = (parseInt(activeKey.value) + 1).toString();
    };

    return {
      labelCol: {
        style: {
          width: "200px",
        },
      },
      wrapperCol: {
        span: 14,
      },
      formState,
      onFinish,
      onFinishFailed,
      activeKey,
      lastStep,
      nextStep,
      plainOptions: [
        {
          label: "质量保证",
          value: "1",
        },
        {
          label: "七天无理由退货",
          value: "2",
        },
      ],
    };
  },
});
</script>
<style lang="less" scoped>
.brick-item {
  .brick-title {
    padding: 0 20px;
    height: 42px;
    line-height: 42px;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .brick-body {
    padding: 20px;
  }
}
.extra {
  display: block;
  margin-top: 20px;
  color: #b2b2b2;
  font-size: 12px;
  line-height: 0.8;
}
.goods-video-box {
  margin-bottom: 10px;
  .goods-video {
    width: 290px;
    height: 140px;
  }
}
.select-time {
  margin-top: 20px;
}
.goods-detail {
  max-width: 750px;
}
.tab-content {
  height: calc(~"100vh - 280px");
  overflow-y: scroll;
}
.form-bar {
  position: fixed;
  bottom: 0;
  left: 284px;
  width: calc(~"100vw - 308px");
  line-height: 80px;
  text-align: center;
  background-color: #fff;
  z-index: 2;
}
</style>